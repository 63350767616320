import React from 'react'

const HomePage = () => {

  const style = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <div style={style}>
      <span>Growing it out</span>
    </div>
  )
}

export default HomePage
